import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./FirstVisitModal.scss";
import useAuth from "../../hooks/useAuth";
import VideoModal from "./VideoModal";
import playIcon from "../../assets/images/play_icon.png";

function FirstVisitModal({ show, setShow }) {
  const { auth } = useAuth();
  const [showVideo, setShowVideo] = useState(false);

  const handleVideoClick = () => {
    setShow(false);
    setShowVideo(true);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="first-visit-modal">
        <div className="video-container">
          <div className="video-player" onClick={handleVideoClick}>
            <div className="video-placeholder">
              <div className="play-button">
                <img src={playIcon} alt="Play Icon" />
              </div>
            </div>
          </div>
          <div className="welcome-text">
            <h2 className="mb-3">👋 Welcome, {auth?.user?.first_name}</h2>
            <p>
              We are here to help you connect, organize, and find opportunities.
            </p>
            <ul>
              <li>Create and manage community requests</li>
              <li>Track social good in real-time</li>
              <li>Collaborate with organizations in need</li>
            </ul>
            <p className="pro-tip">
              💡 Pro Tip: Use the side navigation to explore requests,
              organizations, and opportunities at a glance.
            </p>
            <p className="help-text">
              If you ever need help, reach out via support or replay this
              tutorial anytime.
            </p>
          </div>
          <div className="video-controls">
            <button className="btn btn-link w-100" onClick={handleVideoClick}>
              Watch Tutorial
            </button>
            <button
              className="btn btn-primary w-100"
              onClick={() => setShow(false)}>
              Close
            </button>
          </div>
        </div>
      </Modal>
      <VideoModal show={showVideo} setShow={setShowVideo} />
    </>
  );
}

export default FirstVisitModal;
