import "./Dashboard.scss";
import React, { useState } from "react";
import OrgBadge from "../components/OrgBadge";
import { Col, Row } from "react-bootstrap";
import Box from "../components/Box";

import networkIcon from "../assets/images/icons/network.png";
import collaboratorsIcon from "../assets/images/icons/handshake.png";
import communityRequestsPendingIcon from "../assets/images/icons/circular_arrow.png";
import communityRequestsCompletedIcon from "../assets/images/icons/cube.png";
import CommunityRequestActivityGrid from "../components/dashboard/CommunityRequestActivityGrid";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useFetchStats } from "../api/dashboard";
import CommunityRequestsTable from "../components/dashboard/CommunityRequestsTable";
import TutorialBanner from "../components/TutorialBanner";
import FirstVisitModal from "../components/modals/FirstVisitModal";
import useLocalStorage from "../hooks/useLocalStorage";

function Dashboard() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { data: dashboardStats, isLoading: statsLoading } = useFetchStats();
  const [hasVisited, setHasVisited] = useLocalStorage("hasVisited", false);
  const [showFirstVisit, setShowFirstVisit] = useState(!hasVisited);

  const handleCloseFirstVisit = () => {
    setShowFirstVisit(false);
    setHasVisited(true);
  };

  return (
    <>
      <FirstVisitModal show={showFirstVisit} setShow={handleCloseFirstVisit} />
      <div className="dashboard">
        <OrgBadge org={auth?.user?.organization} />
        <TutorialBanner />
        <Row>
          <Col lg={3}>
            <Box
              className="stat-box"
              loading={statsLoading}
              title="My Network"
              icon={networkIcon}
              onClick={() => navigate("/my-network")}>
              <span className="value">
                {dashboardStats?.result?.orgs_count}
              </span>
            </Box>
          </Col>
          <Col lg={3}>
            <Box
              className="stat-box"
              loading={statsLoading}
              title="Invitations to collaborate"
              icon={collaboratorsIcon}
              onClick={() => navigate("/collaborations")}>
              <span className="value">
                {dashboardStats?.result?.open_collaboration_requests_count}
              </span>
            </Box>
          </Col>
          <Col lg={3}>
            <Box
              className="stat-box"
              loading={statsLoading}
              title="Community requests in progress"
              icon={communityRequestsPendingIcon}
              onClick={() => navigate("/community-requests/published")}>
              <span className="value">
                {dashboardStats?.result?.community_requests_published_count}
              </span>
            </Box>
          </Col>
          <Col lg={3}>
            <Box
              className="stat-box"
              loading={statsLoading}
              title="Completed community requests"
              icon={communityRequestsCompletedIcon}
              onClick={() => navigate("/community-requests/completed")}>
              <span className="value">
                {dashboardStats?.result?.community_requests_completed_count}
              </span>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col>
            <Box className="grid-box">
              <CommunityRequestActivityGrid collapsible={true} limit={10} />
            </Box>
          </Col>
        </Row>
        <Row>
          <Col>
            <Box className="grid-box">
              <CommunityRequestsTable collapsible={true} limit={10} />
            </Box>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
