import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./TutorialBanner.scss";
import useAuth from "../hooks/useAuth";
import VideoModal from "./modals/VideoModal";
import FirstVisitModal from "./modals/FirstVisitModal";
import useLocalStorage from "../hooks/useLocalStorage";
import playIcon from "../assets/images/play_icon.png";

function WatchVideoButton({ setShowVideo }) {
  const [showFirstVisit, setShowFirstVisit] = useState(false);

  return (
    <>
      <div className="watch-video-wrapper">
        <div
          className="watch-video-button"
          onClick={() => setShowFirstVisit(true)}>
          <span
            style={{
              transform: "scaleX(-1)",
            }}>
            🎥
          </span>{" "}
          Watch Tutorial Video
        </div>
      </div>
      <FirstVisitModal show={showFirstVisit} setShow={setShowFirstVisit} />
    </>
  );
}

function TutorialBanner() {
  const { auth } = useAuth();
  const [showVideo, setShowVideo] = useState(false);
  const [hasVisited] = useLocalStorage("hasVisited", false);
  const [isBannerClosed, setIsBannerClosed] = useLocalStorage(
    "bannerClosed",
    false
  );

  if (isBannerClosed) return <WatchVideoButton setShowVideo={setShowVideo} />;

  return (
    <div className="tutorial-banner">
      <div className="close-button" onClick={() => setIsBannerClosed(true)}>
        ×
      </div>
      <Row className="g-4">
        <Col xs="auto">
          <div className="video-thumbnail" onClick={() => setShowVideo(true)}>
            <div className="play-button">
              <img src={playIcon} alt="Play Icon" />
            </div>
          </div>
        </Col>
        <Col>
          <div className="banner-content">
            <h2>👋 Welcome, {auth?.user?.first_name}</h2>
            <p className="mb-0 text">
              Welcome to the Impact Partners Network - your hub for
              collaboration, connection, and community. <br />
              Designed to bring people and ideas to together our intuitive
              platform helps you share, solve, and grow.
            </p>
            <p className="pro-tip mt-2 mb-0">
              💡 Pro Tip: Use the side navigation to explore requests,
              organizations, and opportunities at a glance.
            </p>
          </div>
        </Col>
      </Row>
      <VideoModal show={showVideo} setShow={setShowVideo} />
    </div>
  );
}

export default TutorialBanner;
