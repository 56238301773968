import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import "./VideoModal.scss";

function VideoModal({ show, setShow }) {
  const videoRef = useRef(null);

  const restartVideo = () => {
    if (videoRef.current) {
      // Post message to iframe to restart video
      videoRef.current.contentWindow.postMessage(
        JSON.stringify({ event: "command", func: "seekTo", args: [0] }),
        "*"
      );
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} className="tutorial-modal">
      <div className="video-container">
        <div className="video-player">
          <iframe
            ref={videoRef}
            width="100%"
            height="450"
            src="https://www.youtube.com/embed/1W-A1fSFfWQ?controls=0&enablejsapi=1"
            title="Tutorial Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
        </div>
        <div className="video-controls">
          <button className="btn btn-link w-100" onClick={restartVideo}>
            Restart Video
          </button>
          <button
            className="btn btn-primary w-100"
            onClick={() => setShow(false)}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default VideoModal;
